document.addEventListener("DOMContentLoaded", function () {
    console.log("Protonify Tag Manager v 1.1.0");

    // Helper function to safely add an event listener
    function trackClick(id, eventName, pageName, pageLocation) {
        const element = document.getElementById(id);
        if (!element) {
            console.warn(`trackClick: Element with ID '${id}' not found.`);
            return;
        }
        element.addEventListener("click", function () {
            console.log(`${id} click event fired.`);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': eventName,
                'page_name': pageName,
                'page_location': pageLocation
            });
        });
    }

    // 🔹 Add event listeners safely
    trackClick("index-hero-contact-button", "hero-contact-click", "Index", "Hero");
    trackClick("contact-send-message", "contact-send-click", "Index", "Footer");

    trackClick("about-hero-contact-button", "hero-contact-click", "About", "Hero");
    trackClick("about-contact-button", "about-contact-click", "About", "Team");

    trackClick("markets-hero-contact-button", "hero-contact-click", "Markets", "Hero");
    trackClick("ingredients-contact-button", "ingredients-contact-click", "Market", "Ingredients");

    trackClick("products-hero-contact-button", "hero-contact-click", "Products", "Hero");
    trackClick("quality-contact-button", "quality-contact-click", "Products", "Quality");
    trackClick("lets-talk-contact-button", "talk-contact-click", "Index", "Talk");
    trackClick("refining-hero-contact-button", "hero-contact-click", "Refining", "Hero");
    trackClick("refining-contact-button", "refining-contact-click", "Refining", "Button1");
    trackClick("refining-contact-button2", "refining-contact-click2", "Refining", "Button2");
    trackClick("refining-index-hero", "refining-index-click", "Index", "Refining");
    
    // 🔹 Dynamically attach to product buttons
    const productSKUs = ["1001", "1025", "1015", "1024", "1034"];
    productSKUs.forEach(sku => {
        trackClick(`${sku}-contact-button`, `product-sku-${sku}-click`, "Product", `SKU-${sku}`);
    });
});

/*document.addEventListener("DOMContentLoaded", function () {
    console.log("Protonify Tag Manager v 1.1.0");

    // Helper function to safely add an event listener
    function trackClick(id, eventName, pageName, pageLocation) {
        let element = document.getElementById(id);
        if (element) {
            element.addEventListener("click", function () {
                console.log(`${id} click event fired.`);
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': eventName,
                    'page_name': pageName,
                    'page_location': pageLocation
                });
            });
        }
    }

    // 🔹 Add event listeners safely
    trackClick("index-hero-contact-button", "hero-contact-click", "Index", "Hero");
    trackClick("contact-send-message", "contact-send-click", "Index", "Footer");

    trackClick("about-hero-contact-button", "hero-contact-click", "About", "Hero");
    trackClick("about-contact-button", "about-contact-click", "About", "Team");

    trackClick("markets-hero-contact-button", "hero-contact-click", "Markets", "Hero");
    trackClick("ingredients-contact-button", "ingredients-contact-click", "Market", "Ingredients");

    trackClick("products-hero-contact-button", "hero-contact-click", "Products", "Hero");

    //trackClick("products-hero-contact-button", "hero-contact-click", "Products", "Hero");
    trackClick("lets-talk-contact-button", "talk-contact-click", "Index", "Talk");
    //refining-hero-contact-button
    trackClick("refining-hero-contact-button", "hero-contact-click", "Refining", "Hero");
    //refining-hero-contact-button
    trackClick("refining-contact-button", "refining-contact-click", "Refining", "Body");
    // 🔹 Dynamically attach to product buttons
    let productSKUs = ["1001", "1025", "1015", "1024", "1034"];
    productSKUs.forEach(sku => {
        trackClick(`${sku}-contact-button`, `${sku}-contact-button`, "Product", `SKU-${sku}`);
    });
});

*/